<template>
  <div class="app-context">
    <div class="context note complex">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            class="reform_primary--text main-search-content-text"
          >
            {{ this.player === "수신자" ? "받은 쪽지 확인" : "보낸 쪽지 확인" }}
          </v-col>
          <v-col cols="11">
            <v-card class="mx-auto" elevation="0" outlined>
              <v-card-text>
                <p class="text--primary" style="font-size: 30px; padding: 10px">
                  {{ list.title }}
                </p>
                <p>
                  <v-row style="padding: 0 10px 0px 10px">
                    <v-col md="9" cols="12" style="font-size: 18px">
                      <span v-if="list.transDrHospNm">
                        {{ list.transDrHospNm }}
                      </span>
                      <span v-else>
                        {{ list.recptDrHospNm }}
                      </span>
                      <span
                        v-if="list.transDrNm"
                        style="color: black; margin-left: 15px"
                      >
                        {{ list.transDrNm }}
                      </span>
                      <span v-else style="color: black; margin-left: 15px">
                        {{ list.recptDrNm }}
                      </span>
                    </v-col>
                    <v-col md="3" cols="12" style="font-size: 18px">
                      {{ list.creDt }}
                    </v-col>
                  </v-row>
                </p>
              </v-card-text>
              <v-divider></v-divider>
              <div class="text--primary" style="font-size: 16px; padding: 26px">
                {{ list.conts }}
              </div>
              <v-divider v-if="list.fileNm"></v-divider>
              <v-card-text v-if="list.fileNm">
                <p
                  class="text--primary"
                  style="font-size: 16px; padding-left: 10px; margin: 0px"
                >
                  첨부파일
                  <span
                    v-if="list.fileNm"
                    style="
                      color: blue;
                      cursor: pointer;
                      text-decoration: underline;
                      margin-left: 15px;
                    "
                    @click="fileDownload()"
                  >
                    {{ list.fileNm }}
                    <img
                      src="@/assets/images/icons/paperclip-solid.svg"
                      style="margin-left: 8px; position: relative; top: 3px"
                    />
                  </span>
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="11">
            <v-row class="text-center" justify="center">
              <v-btn
                v-if="this.player === '수신자'"
                @click="writeMessage(list)"
                rounded
                color="#ff7334"
                dark
                style="margin: 30px; width: 90px"
              >
                답장
              </v-btn>
              <v-btn
                @click="deleteDetail()"
                rounded
                color="#146F83"
                dark
                style="margin: 30px; width: 90px; font-weight: bold"
              >
                삭제
              </v-btn>
              <v-btn
                @click="goPrev()"
                rounded
                style="margin: 30px; width: 90px; font-weight: bold"
              >
                목록
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    DefaultPopup,
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },
      sideSet: {
        menuName: "mypage",
        sideTitle: "마이페이지",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      detailInfo: [],
      popupSet: {},
      setCount: false,
      player: "",
      list: [],
    };
  },
  created() {
    window.scrollTo(0, 0);
    // if (history.pushState !== undefined) {
    //   history.pushState({}, "", location.href);
    //   window.onpopstate = () => {
    //     this.handleGoback();
    //   };
    // }
    // console.log("his", history.pushState);
    this.setDetailInfo();
  },
  mounted() {},
  computed: {
    ...mapState("basic", ["checkPopup", "isChildLoading"]),
    ...mapGetters("note", ["GET_NOTE_IDENTITY"]),
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP", "SET_CHILD_LOADER"]),
    ...mapActions("note", [
      "RECPT_DETAIL",
      "TRANS_DETAIL",
      "RECPT_DELETE",
      "TRANS_DELETE",
      "FILE_DOWNLOAD",
    ]),
    listReaded(order) {
      document.querySelector(".tabs__header").childNodes[order].click();
    },
    initAuthInfo() {
      // console.log("앱헤더에서 부를 것");
      this.$refs.appheader.calledNav("withDraw");
    },
    async fileDownload() {
      const filePath = {
        datePath: this.list.creDt.substring(0, 10),
        fileId: this.list.attchNm,
      };

      // console.log("패스 제작 : "+ filePath.datePath +"/"+ filePath.fileId);
      await this.FILE_DOWNLOAD(filePath).then((response) => {
        // console.log(response);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(response);
        link.download = "다운로드_" + new Date().getTime();
        link.click();
      });
    },
    async callPopWithSidePassword() {
      let pop;
      pop = {
        popType: "CheckPasswordOwn",
        title: "비밀번호 확인",
        content: "현재 비밀번호를 입력해주세요.",
        immediatly: true,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
    async deleteDetail() {
      const payload = {
        noteId: this.GET_NOTE_IDENTITY.noteId,
        noteTransId: this.GET_NOTE_IDENTITY.noteTransId,
        noteRecptId: this.GET_NOTE_IDENTITY.noteRecptId,
      };
      if (this.player === "수신자") {
        try {
          await this.RECPT_DELETE(payload).then(() => {
            this.SET_POPUP(true);
            this.popupSet.title = "쪽지 삭제완료";
            this.popupSet.content = "쪽지가 삭제되었습니다.";
            this.popupSet.popType = "custom";
            this.popupSet.cancelBtnText = undefined;
            this.popupSet.confirmBtnText = "확인";
            this.popupSet.nextLink = "/v/mypage/inboxnoteList";
            this.popupSet.destination = true;
          });
        } catch (error) {
          console.log("error :", error);
          this.SET_POPUP(true);
          this.popupSet.title = "쪽지 삭제실패";
          this.popupSet.content = "서버 통신장애로 쪽지를 삭제할 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      } else {
        try {
          await this.TRANS_DELETE(payload).then(() => {
            this.SET_POPUP(true);
            this.popupSet.title = "쪽지 삭제완료";
            this.popupSet.content = "쪽지가 삭제되었습니다.";
            this.popupSet.popType = "custom";
            this.popupSet.cancelBtnText = undefined;
            this.popupSet.confirmBtnText = "확인";
            this.popupSet.nextLink = "/v/mypage/sentnoteList";
            this.popupSet.destination = true;
          });
        } catch (error) {
          console.log("error :", error);
          this.SET_POPUP(true);
          this.popupSet.title = "쪽지 삭제실패";
          this.popupSet.content = "서버 통신장애로 쪽지를 삭제할 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    async setDetailInfo() {
      if (this.checkType()) {
        const payload = {
          noteId: this.GET_NOTE_IDENTITY.noteId,
          noteTransId: this.GET_NOTE_IDENTITY.noteTransId,
          noteRecptId: this.GET_NOTE_IDENTITY.noteRecptId,
        };
        //console.log(this.GET_NOTE_IDENTITY.player);
        if (this.GET_NOTE_IDENTITY.player === "수신자") {
          try {
            await this.RECPT_DETAIL(payload).then((data) => {
              // console.log("넣어본다",data);
              this.list = data.data;
              this.player = "수신자";
              this.list.creDt =
                this.list.creDt.substring(0, 10) +
                " " +
                this.list.creDt.substring(11, 19);
              // console.log("리스트",this.list);
            });
          } catch (error) {
            console.log("error :", error);
          }
        } else {
          try {
            await this.TRANS_DETAIL(payload).then((data) => {
              this.list = data.data;
              this.player = "발신자";
              this.list.creDt =
                this.list.creDt.substring(0, 10) +
                " " +
                this.list.creDt.substring(11, 19);
              // console.log("리스트 확인 : ",this.list);
            });
          } catch (error) {
            console.log("error :", error);
          }
        }
      } else {
        this.goPrev();
      }
    },
    goPrev() {
      this.$router.go(-1);
    },
    checkType() {
      if (Object.keys(this.GET_NOTE_IDENTITY).length !== 0) {
        return true;
      } else {
        return false;
      }
    },
    checkDetailType(param) {
      if (param.noteRecptId !== null) {
        /** recptDetail 진행 */
      } else {
        /** transDetail 진행 */
      }
    },
    // handleGoback() {
    //   if (this.setCount) {
    //     this.$router.push("/v/note/noteList").catch(() => {});
    //   } else {
    //     history.go(1);
    //   }
    //   this.setCount = true;
    //   // 클로즈 함수 구현
    // },
    writeMessage(item) {
      const sendAppheaderItem = {
        method: "writeMessage",
        item: {
          drNm: item.transDrNm,
        },
      };
      this.$emit("send-app-header-method", sendAppheaderItem);
    },
  },
  destroyed() {
    this.setCount = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/request";
@import "@/assets/css/sideContext";
#app {
  .note {
    max-width: 1090px;
    padding: 35px 0 105px 0;
    min-height: 730px;
  }
}
.list-detail {
  border-top: 4px solid #146f83;
  margin: 55px 0 0 0;
  h5.title {
    font-family: SUIT;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 43px;
    letter-spacing: normal;
    text-align: center;
    color: #313c49;
  }
  p {
    font-family: SUIT;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    color: #474747;
  }
  .header {
    h5.title {
      background: #fafafa;
    }
    .article {
      display: flex;
      width: 100%;
      .item {
        display: flex;
        width: 60%;
        h5.title {
          border: 0px solid #707070;
          border-width: 1px 1px 1px 0px;
          width: 130px;
        }
        p {
          border: 0px solid #707070;
          border-width: 1px 0px 1px 0px;
          width: calc(100% - 130px);
          line-height: 43px;
          padding: 0 20px;
        }
        &:nth-of-type(2) {
          border-left: 1px solid #707070;
          width: 40%;
        }
      }
    }
  }
  .content {
    padding: 40px 20px;
    margin: 0 0 60px 0;
    height: 330px;
    border-bottom: 1px solid #707070;
  }
  .btn-wrap {
    display: flex;
    width: 100%;
    justify-content: center;

    button {
      width: 120px;
      border: 0;
      padding: 0;
      font-family: SUIT;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      line-height: 50px;
      background: #bcbcbc;
      margin: 0 0 0 12.5px;
      &:first-of-type {
        margin: 0;
      }
      &.active {
        background: #146f83;
      }
      &.delete {
        background: red;
      }
    }
  }
}
.main-search-content-text {
  font-size: 24px;
  font-weight: 500;
}
</style>
